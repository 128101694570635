<script>
import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarSearch from "@/components/vx/VxAppBarSearch";

export default {
  name: "BackstockHistoryAppBar",
  components: {
    VxAppBar,
    VxAppBarSearch,
  },
  props: {
    filter: {
      type: String,
      default: undefined,
    },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<template>
  <VxAppBar title="Backstock history" mobile-search :filter="filter">
    <template #search="{ back }">
      <VxAppBarSearch
        title="Backstock history"
        :min-chars="3"
        :filter="filter"
        :parsed-filter="parsedFilter"
        @back="back"
      >
      </VxAppBarSearch>
    </template>
  </VxAppBar>
</template>
